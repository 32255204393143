<template>
  <header class="header" :class="{ header_mobile: $store.state.is_menu_shown, header_profile: ($store.state.is_menu_profile_open && $store.state.is_menu_profile_shown) }">

    <div @click="toggleMenu">
      <router-link :to="{name: 'profile'}" v-if="($store.state.user && !$store.state.is_menu_profile_shown) || ($store.state.user && isMobile)" class="header__login header__login_active">
        <span v-if="notices" class="header__login_notices">{{ notices }}</span>
        <img v-if="$store.state.user && $store.state.user.photo" class="b-avatar_image" :src="getImg($store.state.user.photo)" />
      </router-link>

      <a href="#" v-if="!$store.state.user" class="header__login" @click.prevent="loginDialog = !loginDialog">
        <span class="icon-login"></span>
        <span class="header__login_text">{{ 'login_entry' | localize }}</span>
      </a>
    </div>

    <button class="header__theme" v-on:click="setTheme">
      <div class="icon-dark"></div>
      <div class="icon-light"></div>
    </button>

    <template>
      <div class="text-center">
        <v-dialog v-model="loginDialog" width="750">
          <template v-slot:activator="{}"></template>
          <v-card>
            <div class="dialog__modal__logo"><router-link to="/" class="logo">BANKIM<span>ONLINE</span></router-link></div>
            <button class="close dialog__modal_close" @click="closeDialog('loginDialog')"></button>
            <Login class="dialog__modal" modal @passwordRecoveryDialog="openPasswordRecoveryDialog" @goServiceDialog="openServiceDialog" />
          </v-card>
        </v-dialog>
        <v-dialog v-model="passwordRecoveryDialog" width="750">
          <template v-slot:activator="{}"></template>
          <v-card>
            <div class="dialog__modal__logo"><router-link to="/" class="logo">BANKIM<span>ONLINE</span></router-link></div>
            <button class="close dialog__modal_close" @click="closeDialog('passwordRecoveryDialog')"></button>
            <PasswordRecovery class="dialog__modal" modal @codeDialog="openCodeDialog" @finish="openLoginDialog" :step="passwordRecoveryStep" />
          </v-card>
        </v-dialog>
        <v-dialog v-model="codeDialog" width="750">
          <template v-slot:activator="{}"></template>
          <v-card>
            <div class="dialog__modal__logo"><router-link to="/" class="logo">BANKIM<span>ONLINE</span></router-link></div>
            <button class="close dialog__modal_close" @click="closeDialog('codeDialog')"></button>
            <Changes class="dialog__modal" modal recovery @passwordRecoveryDialog="openPasswordRecoveryDialogSecond" />
          </v-card>
        </v-dialog>
        <v-dialog v-model="serviceDialog" width="750">
          <template v-slot:activator="{}"></template>
          <v-card>
            <div class="dialog__modal__logo"><router-link to="/" class="logo">BANKIM<span>ONLINE</span></router-link></div>
            <button class="close dialog__modal_close" @click="closeDialog('serviceDialog')"></button>
            <div class="dialog__modal">
              <div class="modal__container modal__container_modal">
                <div class="modal__title">{{ 'services_title' | localize }}</div>
                <Services />
              </div>
            </div>
          </v-card>
        </v-dialog>
        <v-dialog v-model="returnDialog" width="750">
          <template v-slot:activator="{}"></template>
          <v-card>
            <div class="dialog__modal__logo"><router-link to="/" class="logo">BANKIM<span>ONLINE</span></router-link></div>
            <button class="close dialog__modal_close" @click="closeDialog('returnDialog')"></button>
            <div class="dialog__modal modal__container modal__container_modal">
              <div class="modal__title">{{ 'return_text' | localize }}</div>
              <div class="modal__subtitle">{{ 'return_subtext' | localize }}</div>
              <router-link :to="$store.state.route" tag="button" class="button button__form">{{ 'continue' | localize }}</router-link>
            </div>
          </v-card>
        </v-dialog>
      </div>
    </template>
  </header>
</template>

<script>
import Login from '@/components/Login.vue'
import Services from '@/components/service/List.vue'
import PasswordRecovery from '@/components/PasswordRecovery.vue'
import Changes from '@/components/profile/Changes.vue'
import { commonMixin } from '@/mixins/commonMixin.js'
import { handlerEvent } from '@/handlerEvent'

export default {
  name: 'Header',
  components: { Login, PasswordRecovery, Changes, Services },
  mixins: [commonMixin],
  props: {
    backlink: { type: [String, Object], default: '/' },
    type: { type: String, default: 'inner' },
    title: { type: String, default: 'information' },
    profile: { type: Boolean, default: false },
    step: { type: Number, default: 1 },
    avatar: { type: String, default: null }
  },
  data () {
    return {
      loginDialog: false,
      returnDialog: false,
      serviceDialog: false,
      passwordRecoveryDialog: false,
      passwordRecoveryStep: 1,
      codeDialog: false,
      notices: null
    }
  },
  computed: {
    getImg () {
      return (photo) => {
        return process.env.VUE_APP_API_HOST + photo
      }
    },
    change_notices () { 
      return this.$store.state.notices
    }
  },
  created () { this.calcNotices() },
  watch: { change_notices: function() { this.calcNotices() } },
  methods: {
    calcNotices () {
      this.notices = this.$store.state.notices ? Object.values(this.$store.state.notices).reduce((sum, n) => sum + n, 0) : null
    },
    toggleMenu () {
      if (this.isMobile) this.$store.commit('toggleMenu')
    },
    openPasswordRecoveryDialog () {
      this.loginDialog = false
      this.passwordRecoveryDialog = true
    },
    openCodeDialog () {
      this.passwordRecoveryDialog = false
      this.codeDialog = true
    },
    openServiceDialog () {
      this.loginDialog = false
      this.codeDialog = false
      this.passwordRecoveryDialog = false
      this.serviceDialog = true
    },
    openPasswordRecoveryDialogSecond () {
      this.passwordRecoveryStep = 2
      this.passwordRecoveryDialog = true
      this.codeDialog = false
    },
    openLoginDialog () {
      this.loginDialog = true
      this.passwordRecoveryDialog = false
    },
    saveChange () {
      handlerEvent.$emit('save')
    },
    closeDialog (el) {
      this[el] = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/default/vars.scss";

.b-avatar_image {
  display: none;
}

// Media styles
@media screen and (min-width: 1025px) {
  .b-avatar_image {
    display: block;
    object-fit: cover;
    object-position: center;
    width: 52px;
    height: 52px;
    top: 0;
    z-index: 10;
    border-radius: 50%;
    border: 1px solid $white;
    box-sizing: border-box;
    position: absolute;
  }
  .header__title_display {
    display: none;
  }
}
@media screen and (max-width: 1024px) {
  .header_popup {
    top: 67px;
  }
}
</style>
