<template>
  <div>
    <div class="changes-container" v-if="page === 1">
      <p class="modal__title" v-if="modal">{{ "confirmation" | localize }}</p>
      <div class="changes-container_message changes-container_message__position">
        {{ "on_your" | localize }}
        <span class="changes-container_phone">{{ phone }}</span>
        {{ "was_send_sms" | localize }}
      </div>
      <a href="#" class="changes-container_change-phone changes-container_change-phone__position" @click.prevent="goToPhone" v-if="!recovery">
        {{ "change_action" | localize }}
      </a>
      
      <v-text-field
        class="input input_code input_green"
        type="tel"
        v-mask.hide-on-empty="codeMask"
        outlined
        v-model="codeClient"
        @input="checkForm($v.codeClient)"
        :placeholder="'placeHolder_code' | localize"
        :hint="($v.codeClient.$dirty && !$v.codeClient.required ? 'form_reqiered' : 'empty') | localize"
        :class="{invalid: ($v.codeClient.$dirty && (!$v.codeClient.required || !$v.codeClient.minLength)) || (success && !codeCorrectly)}"
      />

      <div class="changes-container_timing changes-container_timing__position" :class="{ 'changes-container_timing__wait': !activeTimer }">
        0:{{ getTimer }}
        <div class="changes-container_l-half" v-if="activeTimer"></div>
        <div class="changes-container_r-half" v-if="activeTimer"></div>
      </div>
      <a href="#" class="changes-container_send-again changes-container_send-again__position" :class="getClassSendAgain" @click.prevent="sendCodeAgain">
        {{ "send_again" | localize }}
      </a>
      <div class="changes-container_title changes-container_title__position">
        {{ "press_code" | localize }}
      </div>
      <app-button
        :onClick="checkCode"
        :disabled="!success"
        labelBtn="problemWithService_send"
      ></app-button>
      <a href="#" class="changes-container_problems changes-container_problems__position" @click.prevent="page = 2">
        {{ "dont_came_sms" | localize }}
      </a>
    </div>

    <div class="changes-container" v-else-if="page === 4">
      <p class="modal__title" v-if="modal">{{ "confirmation" | localize }}</p>
      <div class="changes-container_message changes-container_message__position">
        {{ "on_your_email" | localize }}
        <span class="changes-container_phone">{{ email }}</span>
        {{ "was_send_email" | localize }}
      </div>
      <a href="#" class="changes-container_change-phone changes-container_change-phone__position" @click.prevent="page = 3" v-if="!recovery">
        {{ "change_action_email" | localize }}
      </a>
      
      <v-text-field
        class="input input_code input_green"
        type="tel"
        v-mask.hide-on-empty="codeMask"
        outlined
        v-model="codeClient"
        @input="checkForm($v.codeClient)"
        :placeholder="'placeHolder_code' | localize"
        :hint="($v.codeClient.$dirty && !$v.codeClient.required ? 'form_reqiered' : 'empty') | localize"
        :class="{invalid: ($v.codeClient.$dirty && (!$v.codeClient.required || !$v.codeClient.minLength)) || (success && !codeCorrectly)}"
      />

      <div class="changes-container_timing changes-container_timing__position" :class="{ 'changes-container_timing__wait': !activeTimer }">
        0:{{ getTimer }}
        <div class="changes-container_l-half" v-if="activeTimer"></div>
        <div class="changes-container_r-half" v-if="activeTimer"></div>
      </div>
      <a href="#" class="changes-container_send-again changes-container_send-again__position" :class="getClassSendAgain" @click.prevent="sendCodeAgain">
        {{ "send_again" | localize }}
      </a>
      <div class="changes-container_title changes-container_title__position">
        {{ "press_code_email" | localize }}
      </div>
      <app-button
        :onClick="checkCode"
        :disabled="!success"
        labelBtn="problemWithService_send"
      ></app-button>
      <div class="changes-container_problems changes-container_problems__position changes-container_problems__text">
        {{ "no_code" | localize }}
        <a href="#" class="changes-container_problems changes-container_problems__link" @click.prevent="page = 2">
          {{ "support" | localize }}
        </a>
      </div>
    </div>

    <div v-else-if="page === 2">
      <div class="problem-services-container">
        <div class="problem-services-container_title problem-services-container_title__position">
          {{ "dont_came_sms" | localize }}
        </div>
        <div class="problem-services-container_message">1.&nbsp;{{ 'problemWithService_radio_one' | localize }}</div>
        <div class="problem-services-container_message">2.&nbsp;{{ 'problemWithService_radio_two' | localize }}</div>
        <div class="problem-services-container_message">3.&nbsp;{{ 'problemWithService_radio_three' | localize }}</div>
        <div class="problem-services-container_message">
          4.&nbsp;{{ 'try' | localize }}&nbsp;
          <a href="#" class="problem-services-container_link" @click.prevent="page = 3">{{ 'another_way' | localize }}</a>
        </div>
        <div class="problem-services-container_text problem-services-container_text__position">
          {{ "fixed" | localize }}
        </div>
        <div class="problem-services-container_buttons">
          <button type="button" class="button button__form button__form_min" @click="page = 1">{{ "yes" | localize }}</button>
          <button type="button" class="button button__form button__form_min" @click="page = 3">{{ "no" | localize }}</button>
        </div>
      </div>
    </div>

    <div v-else>
      <div class="problem-services-container">
        <div class="problem-services-container_title problem-services-container_title__position">
          {{ "dont_came_sms" | localize }}
        </div>
        <div class="problem-services-container_message problem-services-container_message__center">
          {{ "code_to_email" | localize }}
        </div>

        <v-text-field
          type="email"
          class="input input_green"
          :label="'form_email' | localize"
          :placeholder="'placeHolder_email' | localize"
          :class="{invalid: !emailValid}"
          outlined
          v-model="email"
          @input="checkEmail($v.email)"
          :hint="(!$v.email.required ? 'form_reqiered_email' : (!$v.email.alpha ? 'form_wrong_abc_email' : (!$v.email.email ? 'form_wrong_email' : 'empty'))) | localize"
        />
        <button type="button" class="button button__form" :class="{button__form_disabled: !emailValid}" :disabled="!emailValid" @click="sendEmail">{{ "problemWithService_send" | localize }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import AppButton from '@/components/profile/AppButton'
import { formMixin } from '@/mixins/formMixin'
const { required, email, minLength, maxLength } = require('vuelidate/lib/validators')

export default {
  name: 'Code',
  components: { AppButton },
  mixins: [formMixin],
  props: {
    modal: { type: Boolean, default: false },
    nextlink: { type: [String, Object], default: '/profile' },
    recovery: { type: Boolean, default: false }
  },
  data () {
    return {
      page: 1,
      codeMask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
      code: 123456,
      timer: 20,
      sendAgain: false,
      activeTimer: false,
      key: 0,
      codeClient: null,
      timeInterval: null,
      canContinue: false,
      codeCorrectly: true,
      phone: null,
      email: null,
      dialog: false,
      emailValid: false,
    }
  },
  validations: {
    email: { required, email, alpha: val => /^[a-z]/i.test(val) },
    codeClient: { required, minLength: minLength(6), maxLength: maxLength(6) }
  },
  created () {
    this.getTime()
    const tempUser = this.$cookies.get('tempUser') || {}
    this.phone = this.$store.state.user ? this.$store.state.user.phone : tempUser.phone || null
    this.email = this.$store.state.user ? this.$store.state.user.email : tempUser.email || null

    this.checkEmail(this.$v.email)
  },
  watch: { 
    changeUser: function() { 
      this.phone = this.$store.state.user ? this.$store.state.user.phone : tempUser.phone || null
      this.email = this.$store.state.user ? this.$store.state.user.email : tempUser.email || null
    }
  },
  mounted () {
    this.generateCode()
  },
  computed: {
    getTimer () {
      return this.timer
    },
    getClassSendAgain () {
      return this.sendAgain ? 'changes-container_active-send-again' : 'notActive'
    },
    changeUser () { 
      return this.$store.state.user
    }
  },
  methods: {
    sendEmail () {
      // Отправка email
      this.$store.dispatch('sendCode', { code: String(this.code), type: 'email', email: this.email })
      this.page = 4
    },
    checkEmail (field) {
      this.emailValid = field.required && field.email && field.alpha
    },
    getTime () {
      this.activeTimer = true
      this.timeInterval = setInterval(() => {
        let counter = 0
        if (counter < this.timer) {
          this.timer--
          counter++
          this.key++
        } else {
          this.sendAgain = true
          clearInterval(this.timeInterval)
          this.timer = 20
          this.activeTimer = false
        }
      }, 1000)
    },
    checkCode () {
      // TODO DELETE TEST CODE!
      if (this.codeClient == this.code || this.codeClient == 111111) {
        this.codeCorrectly = true

        if (this.recovery) {
          if (this.modal) {
            this.$emit('passwordRecoveryDialog')
          } else {
            this.$router.push({ name: 'Password Recovery', params: { backlink: '/sms', step: 2 } })
          }
        } else {
          const userId = this.$store.state.user ? this.$store.state.user.id : 0
          this.axios
            .put('users/' + userId + '/confirm', { confirmed: 1 })
            .then(() => {
              this.$store.commit('loading', false)
              this.$emit('confirmed')
              this.$router.push(this.nextlink)
            })
            .catch((error) => {
              this.error = error.response.data && error.response.data.error && typeof error.response.data.error === 'string' ? error.response.data.error : 'server_error'
              this.$store.commit('loading', false)
            })
        }
      } else {
        this.codeCorrectly = false
      }
    },
    goToPhone () {
      clearInterval(this.timeInterval)
      this.$emit('goToPhone')
    },
    goToEmail () {
      clearInterval(this.timeInterval)
      this.$emit('goToPhone')
    },
    sendCodeAgain () {
      this.generateCode()
      this.getTime()
      this.sendAgain = false
    },
    sendInformationAboutService () {
      this.dialog = false
    },
    dontSendSms () {
      this.dialog = true
    },
    generateCode () {
      const code = []
      for (let i = 0; i <= 5; i++) {
        code.push(Math.floor(Math.random() * Math.floor(10)))
      }
      this.code = +code.join('')

      // Отправка смс-сообщения
      this.$store.dispatch('sendCode', { code: String(this.code) })
    },
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/default/vars.scss";
.changes-container {
  &_message {
    color: $white;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    text-align: center;
    &__position {
      margin-bottom: 3px;
    }
  }
  &_phone {
    color: $green;
  }
  &_change-phone {
    margin-top: 10px;
    color: $green;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    border-bottom: 1px solid $green;
    transition: all .3s;
    &__position {
      margin-bottom: 50px;
    }
  }
  &_title {
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: $white;
    text-align: center;

    &__position {
      margin-bottom: 30px;
    }
  }
  &_message-error {
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    text-align: center;
    color: $error;
    height: 18px;
  }

  &_timing {
    text-align: center;
    position: relative;
    font-weight: 500;
    font-size: 20px;
    line-height: 70px;
    width: 70px;
    height: 70px;
    color: $green;
    border-radius: 50%;
    &__position {
      margin: 40px 0 30px;
    }
    &__wait {
      box-shadow: inset 0 0 0 3px $green;
    }
  }

  &_l-half,
  &_r-half {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    overflow: hidden;
    
    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border: 3px solid $green;
      animation-duration: 20s;
      animation-iteration-count: 1;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
    }
  }
  &_l-half:before {
    border-right: none;
    border-top-left-radius: 999px;
    border-bottom-left-radius: 999px;
    transform-origin: center right;
    animation-name: l-rotate;
  }
  &_r-half {
    left: 50%;
    &:before {
      border-left: none;
      border-top-right-radius: 999px;
      border-bottom-right-radius: 999px;
      transform-origin: center left;
      animation-name: r-rotate;
    }
  }

  &_code {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      margin-right: -100%;
      width: 100%;
      height: 1px;
      background: $green;
    }

    &__position {
      margin-bottom: 5px;
    }
    &__failed {
      &::after {
        background: $error;
      }
    }
  }
  &_send-again {
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.5);
    border-bottom: 1px solid transparent;
    &__position {
      margin-bottom: 20px;
    }
  }
  .notActive {
    pointer-events: none;
  }
  &_active-send-again {
    color: $green;
    border-bottom: 1px solid $green;
    transition: all .3s;
  }
  &_problems {
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: $white;
    border-bottom: 1px solid $white;
    transition: all .3s;
    &__position {
      margin-top: 20px;
    }
    &__text {
      border: 0;
    }
    &__link {
      color: $green;
      border-bottom: 1px solid $green;
    }
  }
}

.changes__title {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: $white;
}

#app .problem-services-container {
  padding: 50px 140px;

  .v-input {
    width: 100%;
    margin: 50px 0;
  }

  &_buttons {
    display: flex;
    justify-content: space-between;

    .button {
      width: 190px;
    }
  }

  &_title {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    color: white;
    &__position {
      margin-bottom: 30px;
    }
  }
  &_text {
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: white;
    &__position {
      margin: 30px;
    }
  }
  &_message {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: $white;
    text-align: left;
    &__position {
      margin-bottom: 30px;
    }
    &__center {
      text-align: center;
    }
  }
  &_link {
    display: inline-block;
    color: $green;
    border-bottom: 1px solid $green;
  }
}

#app.locale_heb {
  .changes-container_code {
    direction: ltr;

    &::after {
      right: auto;
      left: 0;
      margin-right: 0;
      margin-left: -100%;
    }
  }
  .changes-container_phone {
    direction: ltr;
    display: inline-block;
  }

  .problem-services-container {
    &_message {
      text-align: right;
      &__center {
        text-align: center;
      }
    }
  }
}

#app.light-theme {
  .changes__title,
  .changes-container_title,
  .changes-container_message,
  .changes-container_timing {
    color: $black;
  }
  .changes-container_send-again {
    color: rgba(0, 0, 0, 0.5);
  }
  .changes-container_timing {
    color: $green_dark;
    &__wait {
      box-shadow: inset 0 0 0 3px $green_dark;
    }
  }
  .changes-container_l-half,
  .changes-container_r-half {    
    &:before {
      border: 3px solid $green_dark;
    }
  }
  .changes-container_l-half:before {
    border-right: none;
  }
  .changes-container_r-half:before {
    border-left: none;
  }
  .changes-container_phone {
    color: $green_dark;
  }
  .changes-container_change-phone {
    color: $green_dark;
    border-bottom: 1px solid $green_dark;
  }
  .changes-container_code::after {
    background: $green_dark;
  }
  .changes-container_code__failed {
    &::after {
      background: $error-red;
    }
  }
  .changes-container_active-send-again {
    color: $green_dark;
    border-bottom: 1px solid $green_dark;
  }
  .changes-container_problems {
    color: $black;
    border-bottom: 1px solid $black;
    &__text {
      border: 0;
    }
    &__link {
      color: $green_dark;
      border-bottom: 1px solid $green_dark;
    }
  }

  .problem-services-container {
    &_title {
      color:$black;
    }
    &_text {
      color: $black;
    }
    &_message {
      color: $black;
    }
    &_link {
      color: $green_dark;
      border-bottom: 1px solid $green_dark;
    }
  }
}



@media screen and (max-width: 1024px) {
  .changes-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    &_modal {
      padding: 0;
    }
    &_message {
      color: rgba(255, 255, 255, 0.56);
      font-size: 14px;
    }
    &_email {
      color: white;
    }
    &_code::after {
      display: none;
    }
  }
  #app .problem-services-container {
    padding: 0;

    &_buttons {
      margin: 0 -10px;

      .button {
        width: 100%;
        margin: 0 10px;
        min-width: auto;
      }
    }
  }
}
@media screen and (min-width: 1224px) {
  .changes-container {
    padding: 20px 140px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #app .problem-services-container {
    padding: 50px 140px;
  }
}
</style>
