const mortgage = [
  {
    path: '/mortgage/registration',
    name: 'Mortgage registration',
    props: true,
    meta: { auth_free: true },
    component: () => import('../views/mortgage/step_1/0_Registration.vue')
  },
  {
    path: '/mortgage/members',
    name: 'Mortgage members',
    component: () => import('../views/mortgage/step_1/1_Members.vue')
  },
  {
    path: '/mortgage/client',
    name: 'Mortgage client',
    props: true,
    component: () => import('../views/mortgage/step_1/2_Client.vue')
  },
  {
    path: '/mortgage/information',
    name: 'Mortgage information',
    component: () => import('../views/mortgage/step_1/3_Information.vue')
  },
  {
    path: '/mortgage/reg-payment',
    name: 'Mortgage payment date registration',
    component: () => import('../views/mortgage/step_1/4_Payment.vue')
  },
  {
    path: '/mortgage/reg-bank-offers',
    name: 'Mortgage bank offers registration',
    props: true,
    component: () => import('../views/mortgage/step_1/5_BankOffers.vue')
  },
  {
    path: '/mortgage/realty',
    name: 'Mortgage realty',
    component: () => import('../views/mortgage/step_2/6_Realty.vue')
  },
  {
    path: '/mortgage/sell-and-buy',
    name: 'Mortgage sell and buy',
    component: () => import('../views/mortgage/step_2/7_SellAndBuy.vue')
  },
  {
    path: '/mortgage/already-sold',
    name: 'Mortgage already sold',
    component: () => import('../views/mortgage/step_2/8_AlreadySold.vue')
  },
  {
    path: '/mortgage/old-realty-price',
    name: 'Mortgage old realty price',
    component: () => import('../views/mortgage/step_2/9_OldPrice.vue')
  },
  {
    path: '/mortgage/old-realty-mortgage',
    name: 'Mortgage: old realty mortgage',
    component: () => import('../views/mortgage/step_2/10_OldMortgage.vue')
  },
  {
    path: '/mortgage/sell-during',
    name: 'Mortgage sell during',
    component: () => import('../views/mortgage/step_2/11_SellDuring.vue')
  },
  {
    path: '/mortgage/help',
    name: 'Mortgage help',
    component: () => import('../views/mortgage/step_2/12_Help.vue')
  },
  {
    path: '/mortgage/old-realty-price-not-sold',
    name: 'Mortgage: old realty price not sold',
    component: () => import('../views/mortgage/step_2/13_OldPriceNotSold.vue')
  },
  {
    path: '/mortgage/old-mortgage-not-sold-realty',
    name: 'Mortgage: old mortgage not sold realty',
    component: () =>
      import('../views/mortgage/step_2/14_OldMortgageNotSold.vue')
  },
  {
    path: '/mortgage/old-mortgage-not-sold-price',
    name: 'Mortgage: old mortgage not sold price',
    component: () =>
      import('../views/mortgage/step_2/15_OldMortgageNotSoldPrice.vue')
  },
  {
    path: '/mortgage/self-money',
    name: 'Mortgage self money',
    component: () => import('../views/mortgage/step_2/16_SelfMoney.vue')
  },
  {
    path: '/mortgage/banks',
    name: 'Mortgage banks',
    component: () => import('../views/mortgage/step_3/17_Banks.vue')
  },
  {
    path: '/mortgage/income',
    name: 'Mortgage income',
    component: () => import('../views/mortgage/step_4/18_Income.vue')
  },
  {
    path: '/mortgage/about-incomes',
    name: 'Mortgage about incomes',
    component: () => import('../views/mortgage/step_4/19_AboutIncomes.vue')
  },
  {
    path: '/mortgage/expense',
    name: 'Mortgage expense',
    component: () => import('../views/mortgage/step_4/20_Expense.vue')
  },
  {
    path: '/mortgage/about-expenses',
    name: 'Mortgage about expenses',
    component: () => import('../views/mortgage/step_4/21_AboutExpenses.vue')
  },
  {
    path: '/mortgage/obligations',
    name: 'Mortgage obligations',
    component: () => import('../views/mortgage/step_4/22_Obligations.vue')
  },
  {
    path: '/mortgage/about-obligations',
    name: 'Mortgage about obligations',
    component: () => import('../views/mortgage/step_4/23_AboutObligations.vue')
  },
  {
    path: '/mortgage/problems',
    name: 'Mortgage problems',
    component: () => import('../views/mortgage/step_4/24_Problems.vue')
  },
  {
    path: '/mortgage/about-problems',
    name: 'Mortgage about problems',
    component: () => import('../views/mortgage/step_4/25_AboutProblems.vue')
  },
  {
    path: '/mortgage/delay',
    name: 'Mortgage delay',
    component: () => import('../views/mortgage/step_4/26_Delay.vue')
  },
  {
    path: '/mortgage/about-delay',
    name: 'Mortgage about delay',
    component: () => import('../views/mortgage/step_4/27_AboutDelay.vue')
  },
  {
    path: '/mortgage/something-else',
    name: 'Mortgage something else',
    component: () => import('../views/mortgage/step_4/28_SomethingElse.vue')
  },
  {
    path: '/mortgage/payment',
    name: 'Mortgage payment',
    component: () => import('../views/mortgage/step_4/29_Payment.vue')
  },
  {
    path: '/mortgage/bank-offers',
    name: 'Mortgage bank offers',
    props: true,
    component: () => import('../views/mortgage/step_4/30_BankOffers.vue')
  },
  {
    path: '/mortgage/offer',
    name: 'Mortgage offer',
    props: true,
    component: () => import('../views/mortgage/step_5/31_Offer.vue')
  }
]

export default mortgage
