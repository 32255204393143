<template>
    <footer class="footer" :class="{ footer_profile: $store.state.is_menu_profile_shown && !$store.state.is_menu_profile_open && !$store.state.is_menu_shown }">
        <Social class="mobile"/>
        <nav class="footer__links">
            <ul>
                <li class="footer__link">
                    <router-link to="/terms-of-use">{{ "terms" | localize }}</router-link>
                </li>
                <li class="footer__link">
                    <router-link to="/privacy-policy">{{ "privacy" | localize }}</router-link>
                </li>
                <li class="footer__link">
                    <router-link to="/contacts">{{ "contacts_heb" | localize }}</router-link>
                </li>
            </ul>
        </nav>
        <div class="footer__copy">
            {{ $store.state.params ? $store.state.params.name : 'Bankimonline' }}
            &nbsp;&copy;&nbsp;{{ year }}&nbsp;
            {{ $store.state.params ? $store.state.params.ltd : 'השוואת משכנתא בע״מ' }}
        </div>
    </footer>
</template>

<script>
import Social from '@/components/base/Social.vue'
export default {
  name: 'Footer',
  components: { Social },
  data () {
    return {
      year: new Date().getFullYear()
    }
  }
}
</script>
