const profile = [
  {
    path: '/profile',
    name: 'profile',
    meta: { profile: true },
    props: true,
    component: () => import('../views/profile/Profile.vue')
  },
  {
    path: '/profile/services',
    name: 'services',
    meta: { profile: true },
    component: () => import('../views/profile/Services.vue')
  },
  {
    path: '/profile/documents',
    name: 'documents',
    meta: { profile: true },
    props: true,
    component: () => import('../views/profile/Documents.vue')
  },
  {
    path: '/profile/notifications',
    name: 'notifications',
    meta: { profile: true },
    props: true,
    component: () => import('../views/profile/Notifications.vue')
  },
  {
    path: '/profile/settings',
    name: 'settings',
    meta: { profile: true },
    component: () => import('../views/profile/Settings.vue')
  },
  {
    path: '/profile/payments',
    name: 'payments',
    meta: { profile: true },
    props: true,
    component: () => import('../views/profile/Payments.vue')
  },
  {
    path: '/profile/payments/:id',
    name: 'payment',
    meta: { profile: true },
    component: () => import('../views/profile/Payment.vue')
  }
]
export default profile
