const refinanceMortgage = [
  {
    path: '/refinance-mortgage/registration',
    name: 'Refinance mortgage registration',
    props: true,
    meta: { auth_free: true },
    component: () => import('../views/refinance_mortgage/step_1/0_Registration.vue')
  },
  {
    path: '/refinance-mortgage/members',
    name: 'Refinance mortgage: members',
    component: () => import('../views/refinance_mortgage/step_1/1_Members.vue')
  },
  {
    path: '/refinance-mortgage/client',
    name: 'Refinance mortgage: client',
    props: true,
    component: () => import('../views/refinance_mortgage/step_1/2_Client.vue')
  },
  {
    path: '/refinance-mortgage/about',
    name: 'Refinance mortgage: about mortgage',
    component: () =>
      import('../views/refinance_mortgage/step_1/3_AboutMortgage.vue')
  },
  {
    path: '/refinance-mortgage/programs',
    name: 'Refinance mortgage: programs',
    component: () =>
      import('../views/refinance_mortgage/step_1/4_Programs.vue')
  },
  {
    path: '/refinance-mortgage/reg-payment',
    name: 'Refinance mortgage: reg payment',
    component: () =>
      import('../views/refinance_mortgage/step_1/5_Payment.vue')
  },
  {
    path: '/refinance-mortgage/reg-bank-offers',
    name: 'Refinance mortgage reg bank offers',
    props: true,
    component: () => import('../views/refinance_mortgage/step_2/6_BankOffers.vue')
  },
  {
    path: '/refinance-mortgage/banks',
    name: 'Refinance mortgage: banks',
    component: () => import('../views/refinance_mortgage/step_3/7_Banks.vue')
  },
  {
    path: '/refinance-mortgage/income',
    name: 'Refinance mortgage: income',
    component: () => import('../views/refinance_mortgage/step_4/8_Income.vue')
  },
  {
    path: '/refinance-mortgage/about-incomes',
    name: 'Refinance mortgage: about incomes',
    component: () =>
      import('../views/refinance_mortgage/step_4/9_AboutIncomes.vue')
  },
  {
    path: '/refinance-mortgage/expense',
    name: 'Refinance mortgage: expense',
    component: () => import('../views/refinance_mortgage/step_4/10_Expense.vue')
  },
  {
    path: '/refinance-mortgage/about-expenses',
    name: 'Refinance mortgage: about expenses',
    component: () =>
      import('../views/refinance_mortgage/step_4/11_AboutExpenses.vue')
  },
  {
    path: '/refinance-mortgage/obligations',
    name: 'Refinance mortgage: obligations',
    component: () =>
      import('../views/refinance_mortgage/step_4/12_Obligations.vue')
  },
  {
    path: '/refinance-mortgage/about-obligations',
    name: 'Refinance mortgage: about obligations',
    component: () =>
      import('../views/refinance_mortgage/step_4/13_AboutObligations.vue')
  },
  {
    path: '/refinance-mortgage/problems',
    name: 'Refinance mortgage: problems',
    component: () =>
      import('../views/refinance_mortgage/step_4/14_Problems.vue')
  },
  {
    path: '/refinance-mortgage/about-problems',
    name: 'Refinance mortgage: about problems',
    component: () =>
      import('../views/refinance_mortgage/step_4/15_AboutProblems.vue')
  },
  {
    path: '/refinance-mortgage/reason',
    name: 'Refinance mortgage: reason',
    component: () => import('../views/refinance_mortgage/step_4/16_Reason.vue')
  },
  {
    path: '/refinance-mortgage/type',
    name: 'Refinance mortgage: type',
    component: () => import('../views/refinance_mortgage/step_4/17_Type.vue')
  },
  {
    path: '/refinance-mortgage/increase',
    name: 'Refinance mortgage: increase',
    component: () =>
      import('../views/refinance_mortgage/step_4/18_Increase.vue')
  },
  {
    path: '/refinance-mortgage/decrease',
    name: 'Refinance mortgage: decrease',
    component: () =>
      import('../views/refinance_mortgage/step_4/19_Decrease.vue')
  },
  {
    path: '/refinance-mortgage/something-else',
    name: 'Refinance mortgage: something else',
    component: () =>
      import('../views/refinance_mortgage/step_4/20_SomethingElse.vue')
  },
  {
    path: '/refinance-mortgage/payment',
    name: 'Refinance mortgage: payment',
    component: () =>
      import('../views/refinance_mortgage/step_4/21_Payment.vue')
  },
  {
    path: '/refinance-mortgage/bank-offers',
    name: 'Refinance mortgage bank offers',
    props: true,
    component: () => import('../views/refinance_mortgage/step_4/22_BankOffers.vue')
  },
  {
    path: '/refinance-mortgage/offer',
    name: 'Refinance mortgage: offer',
    props: true,
    component: () => import('../views/refinance_mortgage/step_5/23_Offer.vue')
  }
]

export default refinanceMortgage
