<template>
    <v-app :class="{'light-theme': $store.state.is_light_theme, 'locale_heb': $store.state.is_heb_lang }">
        <div class="layout" :class="{ 
            layout__inner: !isIndex,
            layout__inner_profile: $store.state.is_menu_profile_shown,
            layout__inner_menu: $store.state.is_menu_shown,
            layout__inner_popup: $store.state.is_menu_profile_shown && $store.state.popup.id
            }">
            <Sidebar />
            <Loader />
            <Menu />
            <ProfileMenu v-if="!$store.state.is_menu_shown && $store.state.is_menu_profile_shown"/>

            <Header v-if="isMobile || isIndex" :class="{ header_index: isIndex}" />
            <router-view v-if="isMobile || isIndex" v-show="(!$store.state.is_menu_profile_shown && !$store.state.is_menu_shown) || (!$store.state.is_menu_shown && !$store.state.is_menu_profile_open)" />
            <Footer v-if="isMobile || isIndex" :class="{ footer_index: isIndex}" />

            <perfect-scrollbar v-if="!isIndex && !isMobile" class="layout__inner_container">
                <Header v-if="!isMobile"/>
                <router-view v-show="!$store.state.is_menu_shown" />
                <Footer />
            </perfect-scrollbar>
        </div>
        <v-style v-if="$store.state.is_light_theme">
            html, body { background-color: #fff !important; }
        </v-style>
    </v-app>
</template>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>

<script>
import Sidebar from '@/components/base/Sidebar.vue'
import Header from '@/components/base/Header.vue'
import Footer from '@/components/base/Footer.vue'
import Loader from '@/components/base/Loader.vue'
import Menu from '@/components/base/Menu.vue'
import ProfileMenu from '@/components/profile/Menu.vue'
import { commonMixin } from '@/mixins/commonMixin.js'

export default {
  name: 'App',
  mixins: [commonMixin],
  components: { Footer, Header, Sidebar, Loader, Menu, ProfileMenu },
  data: () => ({ isIndex: false }),
  created () { if (this.$store.state.user && !this.$store.state.notices) this.$store.dispatch('getNotices') },
  updated () {
    this.isIndex = this.$router.currentRoute && this.$router.currentRoute.name && (this.$router.currentRoute.name == 'Index' || this.$router.currentRoute.name == 'Page Not Found')
  }
}
</script>
