<template>
    <div class="layout_index">
        <div class="dots"><i v-for="i in 108" :key="i"></i></div>

        <div class="index__text index__text_1">{{ "index_text_1" | localize }}</div>
        <div class="index__text index__text_2">{{ "index_text_2" | localize }}</div>

        <div class="hline">
            <div class="hline__item">
                 <div class="hline__line hline__line_left">
                    <div class="hline__dot hline__dot_1"></div>
                </div>
            </div>
            <div class="hline__item hline__item_content">{{ "ds" | localize }}</div>
            <div class="hline__item">
                <div class="hline__line hline__line_right">
                    <div class="hline__dot hline__dot_2"></div>
                    <div class="hline__dot hline__dot_3"></div>
                </div>
            </div>
        </div>
        <div class="vline"></div>

        <Services />

        <div class="map"></div>

        <div class="banks">
            <div class="banks__title">{{ "banks" | localize }}</div>
            <ul class="banks__list">
                <li v-for="(bank, k) in active_banks" :key="k">
                    <div class="bank__logo" :class="{bank__logo_in: bank.in, bank__logo_out: bank.out}" :style="'background-image: url(' + getBankLogoUrl(bank.logo) + ');'"></div>
                </li>
            </ul>
        </div>

        <a class="hiw" :href="$store.state.params ? $store.state.params.how_it_works : '#'" target="_blank">
            <div class="hiw_button">{{ "how_it_works" | localize }}</div>
            <div class="hiw_icon"></div>
        </a>

        <div class="sidebar2"></div>

        <a target="_blank" v-if="$store.state.params && $store.state.params.digitize" :href="$store.state.params.digitize" class="button__digitize">{{ 'Digitize your business' }}</a>
    </div>
</template>

<script>
import Services from '@/components/service/List.vue'

export default {
  name: 'Index',
  components: { Services },
  data: () => ({
    banks: [],
    active_banks: [],
    buffer_banks: [],
    timeInterval: null,
    bankIdx: 0,

    // Banks animation timing
    bankOutTime: 700,
    bankInAndOutTime: 3700,
    bankChangeTime: 5000
  }),
  computed: { loaded_banks () { return this.$store.state.banks }},
  watch: { loaded_banks () { this.setBanks() } },
  created () { this.setBanks() },
  destroyed () { clearInterval(this.timeInterval) },
  methods: {
    getBankLogoUrl (logo) {
      return process.env.VUE_APP_ADMIN_URL + '/storage/' + logo
    },
    setBanks () {
      if (this.$store.state.banks && this.$store.state.banks.length > 0) {
        this.banks = this.$store.state.banks
        const active_banks = this.banks.slice(0, 4)
        active_banks.forEach(function (element) { element.in = false; element.out = false })
        this.active_banks = active_banks
        this.buffer_banks = this.banks.slice(4, 99)
        this.animateBanks()
      }
    },
    changeBank(idx) {
      const current = this.active_banks[idx]
      const randomIdx = Math.floor(Math.random() * this.buffer_banks.length)
      this.active_banks[idx] = this.buffer_banks[randomIdx]
      this.buffer_banks[randomIdx] = current
    },
    animateBanks() {
      this.animateBank(this.bankIdx)
      this.timeInterval = setInterval(() => {
        this.bankIdx++
        if (this.bankIdx == 4) this.bankIdx = 0 
        this.animateBank(this.bankIdx)
      }, this.bankChangeTime)
    },
    animateBank (idx) {
      this.active_banks[idx].out = true
      this.$set(this.active_banks, idx, this.active_banks[idx])

      setTimeout(() => { 
        this.changeBank(idx)
        this.active_banks[idx].out = false
        this.active_banks[idx].in = true
        this.$set(this.active_banks, idx, this.active_banks[idx])
      }, this.bankOutTime)
      setTimeout(() => {
        this.active_banks[idx].out = false
        this.active_banks[idx].in = false
        this.$set(this.active_banks, idx, this.active_banks[idx])
      }, this.bankInAndOutTime)
    }
  }
}
</script>
