const credit = [
  {
    path: '/credit/registration',
    name: 'Credit registration',
    props: true,
    meta: { auth_free: true },
    component: () => import('../views/credit/step_1/0_Registration.vue')
  },
  {
    path: '/credit/members',
    name: 'Credit members',
    component: () => import('../views/credit/step_1/1_Members.vue')
  },
  {
    path: '/credit/price',
    name: 'Credit price',
    component: () => import('../views/credit/step_1/2_Price.vue')
  },
  {
    path: '/credit/mortgage',
    name: 'Credit mortgage',
    component: () => import('../views/credit/step_1/3_Mortgage.vue')
  },
  {
    path: '/credit/about-mortgage',
    name: 'Credit: about mortgage',
    component: () => import('../views/credit/step_1/4_AboutMortgage.vue')
  },
  {
    path: '/credit/clients',
    name: 'Credit clients',
    props: true,
    component: () => import('../views/credit/step_2/5_Client.vue')
  },
  {
    path: '/credit/have-credit',
    name: 'Credit have credit',
    component: () => import('../views/credit/step_3/6_HaveCredit.vue')
  },
  {
    path: '/credit/ref-credit',
    name: 'Credit refinance',
    component: () => import('../views/credit/step_3/7_RefCredit.vue')
  },
  {
    path: '/credit/amount-credits',
    name: 'Amount of credits',
    component: () => import('../views/credit/step_3/8_AmountCredits.vue')
  },
  {
    path: '/credit/about-credits',
    name: 'About Credits',
    props: true,
    component: () => import('../views/credit/step_3/9_AboutCredits.vue')
  },
  {
    path: '/credit/reg-payment',
    name: 'Credit reg payment',
    component: () => import('../views/credit/step_3/10_Payment.vue')
  },
  {
    path: '/credit/reg-bank-offers',
    name: 'Credit reg bank offers',
    props: true,
    component: () => import('../views/credit/step_3/11_BankOffers.vue')
  },
  {
    path: '/credit/income',
    name: 'Credit income',
    component: () => import('../views/credit/step_4/12_Income.vue')
  },
  {
    path: '/credit/about-incomes',
    name: 'Credit about incomes',
    component: () => import('../views/credit/step_4/13_AboutIncomes.vue')
  },
  {
    path: '/credit/expense',
    name: 'Credit expense',
    component: () => import('../views/credit/step_4/14_Expense.vue')
  },
  {
    path: '/credit/about-expenses',
    name: 'Credit about expenses',
    component: () => import('../views/credit/step_4/15_AboutExpenses.vue')
  },
  {
    path: '/credit/obligations',
    name: 'Credit obligations',
    component: () => import('../views/credit/step_4/16_Obligations.vue')
  },
  {
    path: '/credit/about-obligations',
    name: 'Credit about obligations',
    component: () => import('../views/credit/step_4/17_AboutObligations.vue')
  },
  {
    path: '/credit/problems',
    name: 'Credit problems',
    component: () => import('../views/credit/step_4/18_Problems.vue')
  },
  {
    path: '/credit/about-problems',
    name: 'Credit about problems',
    component: () => import('../views/credit/step_4/19_AboutProblems.vue')
  },
  {
    path: '/credit/delay',
    name: 'Credit delay',
    component: () => import('../views/credit/step_4/20_Delay.vue')
  },
  {
    path: '/credit/about-delay',
    name: 'Credit about delay',
    component: () => import('../views/credit/step_4/21_AboutDelay.vue')
  },
  {
    path: '/credit/something-else',
    name: 'Credit something else',
    component: () => import('../views/credit/step_4/22_SomethingElse.vue')
  },
  {
    path: '/credit/payment',
    name: 'Credit payment',
    component: () => import('../views/credit/step_4/23_Payment.vue')
  },
  {
    path: '/credit/bank-offers',
    name: 'Credit bank offers',
    props: true,
    component: () => import('../views/credit/step_4/24_BankOffers.vue')
  },
  {
    path: '/credit/offer',
    name: 'Credit offer',
    props: true,
    component: () => import('../views/credit/step_5/25_Offer.vue')
  }
]

export default credit
