<template>
    <div class="sidebar">
        <div class="shadow" @click="$store.commit('toggleMenu')"></div>

        <router-link to="/" class="logo">BANKIM<span>ONLINE</span></router-link>

        <button class="lang desktop">
            <div class="lang__link" @click="setLocale('ru')" :class="{ lang__link_active: $store.state.lang == 'ru' }">RU</div>
            <div class="lang__link" @click="setLocale('he')" :class="{ lang__link_active: $store.state.lang == 'he' }">HEB</div>
            <div class="lang__link" @click="setLocale('en')" :class="{ lang__link_active: $store.state.lang == 'en' }">ENG</div>
        </button>

        <button class="lang mobile" @click="setLocale($store.state.lang == 'ru' ? 'he' : ($store.state.lang == 'he' ? 'en' : 'ru'))">
            <div class="lang__link" :class="{ lang__link_active: $store.state.lang == 'ru' }">RU</div>
            <div class="lang__link" :class="{ lang__link_active: $store.state.lang == 'he' }">HEB</div>
            <div class="lang__link" :class="{ lang__link_active: $store.state.lang == 'en' }">ENG</div>
        </button>

        <button class="burger" :class="{ burger_active: $store.state.is_menu_shown }" @click="$store.commit('toggleMenu')"><span></span></button>

        <Social cls="social"/>
    </div>
</template>

<script>
import { commonMixin } from '@/mixins/commonMixin.js'
import Social from '@/components/base/Social.vue'

export default {
  name: 'Sidebar',
  mixins: [commonMixin],
  components: { Social },
  data () {
    return {
      openMenu: false
    }
  },
  computed: {
    getImg () {
      return (photo) => {
        return process.env.VUE_APP_API_HOST + photo
      }
    }
  }
}
</script>
