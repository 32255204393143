import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import store from '@/store'
import he from 'vuetify/es5/locale/he'
import ru from 'vuetify/es5/locale/ru'
import en from 'vuetify/es5/locale/en'

Vue.use(Vuetify)

export default new Vuetify({
  rtl: store.state.is_heb_lang,
  lang: {
    locales: { he, ru, en },
    current: store.state.lang
  },
  icons: {
    values: {
      calendar: '...'
    }
  },
  theme: {
    dark: !store.state.is_light_theme,
    default: 'dark',
    disable: true,
    options: {
      cspNonce: undefined,
      customProperties: undefined,
      minifyTheme: undefined,
      themeCache: undefined
    },
    themes: {
      light: {
        primary: '#020266',
        secondary: '#424242',
        accent: '#020266',
        error: '#FF5252',
        info: '#FF8E8E',
        success: '#4CAF50',
        warning: '#FB8C00'
      },
      dark: {
        primary: '#F8F8F8',
        secondary: '#424242',
        accent: '#020266',
        error: '#FF8E8E',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00'
      }
    }
  }
})
