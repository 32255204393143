import store from '../store'

export default function localizeFilter (key, text) {
  const locales = store.state.locales || {}

  if (!locales[key]) {
    return process.env.VUE_APP_ENV == 'production' ? null : `[Locale error]: ${key}`
  }

  if (locales[key].indexOf(':text') + 1) {
    return locales[key].replace(/:text/, text || '')
  }

  return locales[key]
}
