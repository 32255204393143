<template>
  <div class="modal__container" :class="{ 'modal__container_modal': modal }">
    <div class="modal__title" v-if="modal">{{ 'passwordRecoveryTitle' | localize }}</div>

    <div v-if="step === 1">
      <div class="modal__subtitle">{{ 'passwordRecoverySubTitle' | localize }}</div>

      <div class="modal__inputs">
        <div class="form__group">
          <v-text-field
            class="input input_green"
            :label="'login_placeholder' | localize"
            :placeholder="'login_placeholder' | localize"
            outlined
            v-model="login"
            @input="checkForm($v.login)"
            :hint="($v.login.$dirty && !$v.login.required ? 'form_reqiered' : 'empty') | localize"
            :class="{invalid:$v.login.$dirty && !$v.login.required}"
          />
        </div>
      </div>

      <button type="button" class="button button__form" :disabled="!success" :class="{ button__form_disabled: !success }" @click="submitHandler">
        {{ "passwordRecoveryNext" | localize }}
      </button>
    </div>

    <div v-if="step === 2">

      <div class="form__group">
        <v-text-field
          tabindex="1"
          class="input"
          :append-icon="showNewPassword ? 'icon-eye-on' : 'icon-eye-off-1'"
          :label="'new_password' | localize"
          outlined
          v-model="newPassword"
          @click:append="showNewPassword = !showNewPassword"
          :type="showNewPassword ? 'text' : 'password'"
          @input="checkPassword($v.newPassword)"
          :hint="getHintNewPassword($v.newPassword) | localize"
          :class="{invalid: $v.newPassword.$dirty && (!$v.newPassword.minLength || !$v.newPassword.maxLength)}"
        />
        <v-text-field
          tabindex="2"
          class="input"
          :label="'new_password_again' | localize"
          outlined
          v-model="asPassword"
          :append-icon="showAsPassword ? 'icon-eye-on' : 'icon-eye-off-1'"
          @click:append="showAsPassword = !showAsPassword"
          :type="showAsPassword ? 'text' : 'password'"
          @input="checkPassword($v.newPassword)"
          :hint="($v.asPassword.$dirty && !$v.asPassword.sameAs ? 'passwords_must_match' : 'empty') | localize"
          :class="{invalid: $v.asPassword.$dirty && !$v.asPassword.sameAs}"
        />
      </div>

      <button type="button" class="button button__form" :disabled="!passwordValid" :class="{ button__form_disabled: !passwordValid }" @click="submitHandlerPassword">
        {{ "save" | localize }}
      </button>
    </div>

    <div class="error__container">
      <p class="error" v-if="error">{{ error | localize }}</p>
    </div>
  </div>
</template>

<script>
import { formMixin } from '@/mixins/formMixin'
const { required, minLength, maxLength, sameAs } = require('vuelidate/lib/validators')

export default {
  name: 'PasswordRecovery',
  props: {
    modal: { type: Boolean, default: false },
    step: { type: Number, default: 1 }
  },
  mixins: [formMixin],
  data () {
    return {
      login: null,
      newPassword: null,
      asPassword: null,
      showNewPassword: false,
      showAsPassword: false,
      passwordValid: false,
      error: null
    }
  },
  methods: {
    checkPassword (field) {
      this.checkForm(field)
      this.passwordValid = field.minLength && field.maxLength && this.$v.asPassword.sameAs
    },
    submitHandler () {
      this.$store.commit('loading', true)
      this.axios
        .get('password_recovery', { params: { login: this.login } })
        .then((response) => {
          this.$cookies.set('tempUser', response.data)
          this.$store.commit('loading', false)
          if (this.modal) {
            this.$emit('codeDialog')
          } else {
            this.$router.push({ name: 'SMS', params: { backlink: '/password-recovery', recovery: true } })
          }
        })
        .catch((error) => {
          this.$store.commit('loading', false)
          this.error = error.response.data && error.response.data.error && typeof error.response.data.error === 'string' ? error.response.data.error : 'server_error'
        })
    },
    submitHandlerPassword () {
      this.$store.commit('loading', true)
      const tempUser = this.$cookies.get('tempUser') || {}
      this.axios
        .post('password_recovery', {
          user_id: tempUser.id || null,
          phone: tempUser.phone || null,
          password: this.newPassword
        })
        .then((response) => {
          this.$cookies.set('tempUser', response.data)
          this.$store.commit('loading', false)
          if (this.modal) {
            this.$emit('finish')
          } else {
            this.$router.push('/login')
          }
        })
        .catch((error) => {
          this.$store.commit('loading', false)
          this.error = error.response.data && error.response.data.error && typeof error.response.data.error === 'string' ? error.response.data.error : 'server_error'
        })
    }
  },
  computed: {
    getHintNewPassword () {
      return (field) => {
        if (field.$dirty) {
          if (!field.minLength || !field.maxLength) {
            return 'passwords_length'
          }
          return 'empty'
        } else {
          return 'empty'
        }
      }
    }
  },
  validations: {
    login: { required },
    newPassword: { minLength: minLength(8), maxLength: maxLength(40) },
    asPassword: { sameAs: sameAs('newPassword') }
  }
}
</script>
