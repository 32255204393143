<template>
  <div class="menu" :class="{ menu_active: $store.state.is_menu_shown, menu_submenu: submenuOpen }">
    <div class="mobile">
      <nav class="menu__nav" v-if="$store.state.is_menu_shown">
        <ul>
          <li class="menu__item" :class="{ menu__item_active: menu.section}" @click="submenu('section')">
            <div class="menu__item_pointer"></div>
            {{ "menu_service" | localize }}
            <div class="menu__section" :class="{ menu__section_active: menu.section }">
              <ul class="menu__list">
                <li class="menu__item" @click="$store.commit('toggleMenu')"><router-link to="/credit/registration">{{ "menu_credit" | localize }}</router-link></li>
                <li class="menu__item" @click="$store.commit('toggleMenu')"><router-link to="/refinance-credit/registration">{{ "refinance_credit" | localize }}</router-link></li>
                <li class="menu__item" @click="$store.commit('toggleMenu')"><router-link to="/mortgage/registration">{{ "mortgage" | localize }}</router-link></li>
                <li class="menu__item" @click="$store.commit('toggleMenu')"><router-link to="/refinance-mortgage/registration">{{ "refinance_mortgage" | localize }}</router-link></li>
              </ul>
            </div>
          </li>
          <li class="menu__item" :class="{ menu__item_active: menu.docs}" @click="submenu('docs')">
            <div class="menu__item_pointer"></div>
            {{ "menu_docs" | localize }}
            <div class="menu__section" :class="{ menu__section_active: menu.docs }">
              <ul class="menu__list">
                <li class="menu__item" @click="$store.commit('toggleMenu')"><router-link to="/profile/documents">{{ "example_documents" | localize }}</router-link></li>
              </ul>
            </div>
          </li>
          <li class="menu__item" :class="{ menu__item_active: menu.banks}" @click="submenu('banks')">
            <div class="menu__item_pointer"></div>
            {{ "banks" | localize }}
            <div class="menu__section" :class="{ menu__section_active: menu.banks }">
              <ul class="menu__list">
                <li class="menu__item" v-for="(bank, k) in $store.state.banks" :key="k">
                  <a :href="bank.url" target="_blank">
                    {{ bank['name_' + ($store.state.lang)] }}
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li class="menu__item" :class="{ menu__item_active: menu.cooperation}" @click="goto('Cooperation')">{{ "cooperation" | localize }}</li>
          <li class="menu__item" :class="{ menu__item_active: menu.about}" @click="goto('About')">{{ "menu_about" | localize }}</li>
          <li class="menu__item" :class="{ menu__item_active: menu.lawyers}" @click="goto('Lawyer')">{{ "lawyer_tenders" | localize }}</li>
          <li class="menu__item" :class="{ menu__item_active: menu.brokers}" @click="goto('Broker')">{{ "broker_tenders" | localize }}</li>
          <li class="menu__item" :class="{ menu__item_active: menu.vacancies}" @click="goto('Vacancy')">{{ "v_title" | localize }}</li>
        </ul>
      </nav>
    </div>
    <div class="desktop">
      <div class="menu__container">
        <nav class="menu__nav" v-if="$store.state.is_menu_shown">
          <ul>
            <li class="menu__item" :class="{ menu__item_active: menu.section}" @click="submenu('section')">{{ "menu_service" | localize }}</li>
            <li class="menu__item" :class="{ menu__item_active: menu.docs}" @click="submenu('docs')">{{ "menu_docs" | localize }}</li>
            <li class="menu__item" :class="{ menu__item_active: menu.banks}" @click="submenu('banks')">{{ "banks" | localize }}</li>
            <li class="menu__item" :class="{ menu__item_active: menu.cooperation}" @click="goto('Cooperation')">{{ "cooperation" | localize }}</li>
            <li class="menu__item" :class="{ menu__item_active: menu.about}" @click="goto('About')">{{ "menu_about" | localize }}</li>
            <li class="menu__item" :class="{ menu__item_active: menu.lawyers}" @click="goto('Lawyer')">{{ "lawyer_tenders" | localize }}</li>
            <li class="menu__item" :class="{ menu__item_active: menu.brokers}" @click="goto('Broker')">{{ "broker_tenders" | localize }}</li>
            <li class="menu__item" :class="{ menu__item_active: menu.vacancies}" @click="goto('Vacancy')">{{ "v_title" | localize }}</li>
          </ul>
        </nav>
      </div>
      <div class="menu__sections" v-if="$store.state.is_menu_shown">
        <div class="menu__section" :class="{ menu__section_active: menu.section }">
          <ul class="menu__list">
            <li class="menu__item" @click="$store.commit('toggleMenu')"><router-link to="/credit/registration">{{ "menu_credit" | localize }}</router-link></li>
            <li class="menu__item" @click="$store.commit('toggleMenu')"><router-link to="/refinance-credit/registration">{{ "refinance_credit" | localize }}</router-link></li>
            <li class="menu__item" @click="$store.commit('toggleMenu')"><router-link to="/mortgage/registration">{{ "mortgage" | localize }}</router-link></li>
            <li class="menu__item" @click="$store.commit('toggleMenu')"><router-link to="/refinance-mortgage/registration">{{ "refinance_mortgage" | localize }}</router-link></li>
          </ul>
        </div>
        <div class="menu__section" :class="{ menu__section_active: menu.docs }">
          <ul class="menu__list">
            <li class="menu__item">&nbsp;</li>
            <li class="menu__item" @click="$store.commit('toggleMenu')"><router-link to="/profile/documents">{{ "example_documents" | localize }}</router-link></li>
            <li class="menu__item">&nbsp;</li>
            <li class="menu__item">&nbsp;</li>
          </ul>
        </div>
        <div class="menu__section" :class="{ menu__section_active: menu.banks }">
          <ul class="menu__list">
            <li class="menu__item" v-for="(bank, k) in $store.state.banks" :key="k">
              <a :href="bank.url" target="_blank">
                {{ bank['name_' + ($store.state.lang)] }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { commonMixin } from '@/mixins/commonMixin.js'

export default {
  name: 'Menu',
  mixins: [commonMixin],
  data () {
    return {
      openMenu: false,
      submenuOpen: false,
      scheme: { section: false, docs: false, banks: false, about: false, lawyers: false, brokers: false, vacancies: false, about: false, cooperation: false },
      menu: null
    }
  },
  created() {
    this.menu = JSON.parse(JSON.stringify(this.scheme))
    this.checkActiveMenu()
  },
  updated() {
    if (!this.$store.state.is_menu_shown) {
      this.submenuOpen = false
      this.menu = JSON.parse(JSON.stringify(this.scheme))
    }
  },
  computed: { is_menu_shown () { return this.$store.state.is_menu_shown }},
  watch: { is_menu_shown: function() { this.checkActiveMenu() }},
  methods: {
    checkActiveMenu () {
      if (this.$router.currentRoute.name == 'Lawyer') this.menu.lawyers = true
      else if (this.$router.currentRoute.name == 'Broker') this.menu.brokers = true
      else if (this.$router.currentRoute.name == 'Vacancy') this.menu.vacancies = true
      else if (this.$router.currentRoute.name == 'About') this.menu.about = true
      else if (this.$router.currentRoute.name == 'Cooperation') this.menu.cooperation = true
      else this.menu = JSON.parse(JSON.stringify(this.scheme))
    },
    submenu(item) {
      if (this.menu[item] === true) return
      this.submenuOpen = !this.menu[item]
      this.menu = JSON.parse(JSON.stringify(this.scheme))
      if (this.submenuOpen) this.menu[item] = true
    },
    goto(page_name) {
      this.$store.commit('toggleMenu')
      if (page_name == this.$router.currentRoute.name) return
      this.$router.push({name: page_name})
    }
  }
}
</script>
