import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Vuelidate from 'vuelidate'
import VueCookies from 'vue-cookies'
import VueScrollTo from 'vue-scrollto'
import vuetify from './plugins/vuetify'
import VueMask from 'v-mask'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import * as VueGoogleMaps from 'vue2-google-maps'

import App from './App.vue'
import router from './router'
import store from './store'

import localizeFilter from './filters/localize.filter'

const API_VERSION = process.env.VUE_APP_API_VERSION
const API_HOST = process.env.VUE_APP_API_HOST
const GOOGLE_MAP_API_KEY = process.env.VUE_APP_GOOGLE_MAP_API_KEY

axios.defaults.baseURL = API_HOST + '/api/' + API_VERSION + '/'
axios.defaults.headers.common.Accept = 'application/json'
axios.defaults.headers.common.Locale = store.state.lang
if (store.state.user && store.state.user.token) {
  axios.defaults.headers.common['X-Authorization'] = `Bearer ${store.state.user.token}`
}

Vue.config.productionTip = false

Vue.use(Vuelidate)
Vue.use(VueCookies)
Vue.use(VueScrollTo)
Vue.use(VueAxios, axios)
Vue.use(VueMask)
Vue.use(PerfectScrollbar)
Vue.use(VueGoogleMaps, {
  load: {
    key: GOOGLE_MAP_API_KEY,
    libraries: '',
    language: store.state.lang
  }
})

Vue.$cookies.config('1m')
Vue.filter('localize', localizeFilter)

Vue.directive("filter", {
  bind: function(el, binding) {
    this.inputHandler = function(e) {
      var ch = String.fromCharCode(e.which);
      var re = new RegExp(binding.value);
      if (!ch.match(re)) {
        e.preventDefault();
      }
    };
    el.addEventListener("keypress", this.inputHandler);
  },
  unbind: function(el) {
    el.removeEventListener("keypress", this.inputHandler);
  },
  inputHandler: null
});

Vue.component('v-style', {
  render: function (createElement) {
    return createElement('style', this.$slots.default)
  }
})

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
