import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import store from '../store'

import mortgage from './mortgage'
import refinanceMortgage from './refinance_mortgage'
import credit from './credit'
import refinanceCredit from './refinance_credit'
import profile from './profile'

Vue.use(VueRouter)

const baseRoutes = [
  {
    path: '/',
    name: 'Index',
    meta: { auth_free: true },
    component: Index
  },
  {
    path: '/registration',
    name: 'Registration',
    meta: { auth_free: true },
    props: true,
    component: () => import('../views/Registration.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta: { auth_free: true },
    props: true,
    component: () => import('../views/Login.vue')
  },
  {
    path: '/password-recovery',
    name: 'Password Recovery',
    meta: { auth_free: true },
    props: true,
    component: () => import('../views/PasswordRecovery.vue')
  },
  {
    path: '/sms',
    name: 'SMS',
    meta: { auth_free: true },
    props: true,
    component: () => import('../views/SMS.vue')
  },
  {
    path: '/payment',
    name: 'Payment',
    props: true,
    component: () => import('../views/Payment.vue')
  },
  {
    path: '/bank-offers',
    name: 'Bank offers',
    props: true,
    component: () => import('../views/BankOffers.vue')
  },
  {
    path: '/meet',
    name: 'Meet',
    props: true,
    component: () => import('../views/Meet.vue')
  },
  {
    path: '/credit',
    name: 'Credit',
    meta: { auth_free: true },
    component: () => import('../views/credit/Service.vue')
  },
  {
    path: '/mortgage',
    name: 'Mortgage',
    meta: { auth_free: true },
    component: () => import('../views/mortgage/Service.vue')
  },
  {
    path: '/about',
    name: 'About',
    meta: { auth_free: true },
    component: () => import('../views/About.vue')
  },
  {
    path: '/contract',
    name: 'Contract',
    props: true,
    component: () => import('../views/text_pages/Contract.vue')
  },
  {
    path: '/terms-of-use',
    name: 'Terms of use',
    meta: { auth_free: true },
    props: true,
    component: () => import('../views/text_pages/TermsOfUse.vue')
  },
  {
    path: '/privacy-policy',
    name: 'Privacy policy',
    meta: { auth_free: true },
    props: true,
    component: () => import('../views/text_pages/Policy.vue')
  },
  {
    path: '/return-policy',
    name: 'Return policy',
    meta: { auth_free: true },
    component: () => import('../views/text_pages/ReturnPolicy.vue')
  },
  {
    path: '/cookies',
    name: 'Cookies',
    meta: { auth_free: true },
    component: () => import('../views/text_pages/Cookies.vue')
  },
  {
    path: '/service-info',
    name: 'ServiceInfo',
    meta: { auth_free: true },
    props: true,
    component: () => import('../views/text_pages/ServiceInfo.vue')
  },
  {
    path: '/approve-timing',
    name: 'ApproveTiming',
    meta: { auth_free: true },
    props: true,
    component: () => import('../views/text_pages/ApproveTiming.vue')
  },
  {
    path: '/bank-permission',
    name: 'BankPermission',
    meta: { auth_free: true },
    props: true,
    component: () => import('../views/text_pages/BankPermission.vue')
  },
  {
    path: '/contacts',
    name: 'Contacts',
    meta: { auth_free: true },
    component: () => import('../views/Contacts.vue')
  },
  {
    path: '/failure',
    name: 'Failure',
    meta: { auth_free: true },
    component: () => import('../views/Failure.vue')
  },
  {
    path: '*',
    name: 'Page Not Found',
    meta: { auth_free: true },
    component: () => import('../views/404.vue')
  },

  // Адвокатам
  {
    path: '/for-lawyers',
    name: 'Lawyer',
    meta: { auth_free: true },
    component: () => import('../views/common/Lawyer.vue')
  },
  {
    path: '/lawyers-questionnaire',
    name: 'Lawyers Questionnaire',
    meta: { auth_free: true },
    component: () => import('../views/common/LawyerQuestionnaire.vue')
  },
  {
    path: '/lawyers-benefits',
    name: 'Lawyers Benefits',
    meta: { auth_free: true },
    component: () => import('../views/common/LawyerBenefits.vue')
  },

  // Брокерам
  {
    path: '/for-brokers',
    name: 'Broker',
    meta: { auth_free: true },
    component: () => import('../views/common/Broker.vue')
  },
  {
    path: '/brokers-questionnaire',
    name: 'Brokers Questionnaire',
    meta: { auth_free: true },
    component: () => import('../views/common/BrokerQuestionnaire.vue')
  },
  {
    path: '/brokers-benefits',
    name: 'Brokers Benefits',
    meta: { auth_free: true },
    component: () => import('../views/common/BrokerBenefits.vue')
  },

  // Вакансии
  {
    path: '/vacancy',
    name: 'Vacancy',
    meta: { auth_free: true },
    component: () => import('../views/common/Vacancy.vue')
  },
  {
    path: '/aspirants-questionnaire',
    name: 'Aspirant Questionnaire',
    meta: { auth_free: true },
    props: true,
    component: () => import('../views/common/AspirantQuestionnaire.vue')
  },

  // Сотрудничетсво
  {
    path: '/cooperation',
    name: 'Cooperation',
    meta: { auth_free: true },
    component: () => import('../views/common/Cooperation.vue')
  },
]

const routes = baseRoutes.concat(
  mortgage,
  refinanceMortgage,
  credit,
  refinanceCredit,
  profile
)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach(async (to, from, next) => {
  // Загрузка локализации и данных сайта
  if (!store.state.locales) await store.dispatch('getData')

  // Меню профиля
  if (to.meta.profile) store.commit('openProfileMenu')
  else store.commit('closeProfileMenu')

  // Редирект для авторизации
  const requireAuth = to.matched.some((record) => !record.meta.auth_free)
  if (requireAuth && !store.state.user) {
    next('/login')
  } else {
    next()
  }
})

export default router
