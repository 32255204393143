<template>
  <div class="modal__container" :class="{ 'modal__container_modal': modal }">
    <div class="modal__title" v-if="modal">{{ 'login_Big_Title' | localize }}</div>

    <div class="modal__inputs">
      <div class="form__group">
        <v-text-field
          class="input input_green"
          :label="'login' | localize"
          :placeholder="'login_placeholder' | localize"
          outlined
          v-model="login"
          @input="checkForm($v.login)"
          :hint="($v.login.$dirty && !$v.login.required ? 'form_reqiered' : 'empty') | localize"
          :class="{invalid:$v.login.$dirty && !$v.login.required}"
        />
      </div>

      <div class="form__group">
        <v-text-field
          class="input input_green"
          :append-icon="showPassword ? 'icon-eye-on' : 'icon-eye-off-1'"
          :label="'password' | localize"
          outlined
          v-model="password"
          @click:append="showPassword = !showPassword"
          :type="showPassword ? 'text' : 'password'"
          @input="checkForm($v.password)"
          :hint="getHintNewPassword($v.password) | localize"
          :class="{invalid: $v.password.$dirty && (!$v.password.required || !$v.password.minLength || !$v.password.maxLength)}"
        />
      </div>

      <div class="modal__text modal__text_side modal__text_wide">
        <a href="#" @click.prevent="passwordRecoveryDialog" v-if="modal" class="modal__link">{{ 'forget_password' | localize }}</a>
        <router-link v-else :to="{ name: 'Password Recovery', params: { backlink: '/login' } }" class="modal__link">{{ 'forget_password' | localize }}</router-link>
      </div>
    </div>

    <div class="modal__text modal__text_wide">
      {{ 'login_text_1' | localize }}
      <a href="#" @click.prevent="goServiceDialog" class="modal__link">{{ 'login_text_2' | localize }}</a>
    </div>

    <button type="button" class="button button__form" :disabled="!success" :class="{ button__form_disabled: !success }" @click="submitHandler">
      {{ "login_entry" | localize }}
    </button>

    <div class="error__container">
      <p class="error" v-if="error">{{ error | localize }}</p>
    </div>
  </div>
</template>

<script>
import { formMixin } from '@/mixins/formMixin'
const { required, minLength, maxLength } = require('vuelidate/lib/validators')

export default {
  name: 'Login',
  props: {
    modal: { type: Boolean, default: false }
  },
  mixins: [formMixin],
  data () {
    return {
      login: null,
      password: null,
      showPassword: false,
      error: null
    }
  },
  methods: {
    passwordRecoveryDialog () {
      this.$emit('passwordRecoveryDialog')
    },
    goServiceDialog () {
      this.$emit('goServiceDialog')
    },
    submitHandler () {
      this.$store.commit('loading', true)
      this.axios
        .post('login', {
          login: this.login,
          password: this.password,
          device_name: navigator.userAgent
        })
        .then((response) => {
          this.$store.commit('saveUser', response.data)
          location.href = '/profile'
        })
        .catch((error) => {
          this.$store.commit('loading', false)
          this.error = error.response.data && error.response.data.error && typeof error.response.data.error === 'string' ? error.response.data.error : 'server_error'
        })
    }
  },
  computed: {
    getHintNewPassword () {
      return (field) => {
        if (field.$dirty) {
          if (!field.required) {
            return 'form_reqiered'
          }
          if (!field.minLength || !field.maxLength) {
            return 'passwords_length'
          }
          return 'empty'
        } else {
          return 'empty'
        }
      }
    }
  },
  validations: {
    login: { required },
    password: { required, minLength: minLength(8), maxLength: maxLength(40) }
  }
}
</script>
