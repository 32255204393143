import Vue from 'vue'
import Vuex from 'vuex'
import VueCookies from 'vue-cookies'
import VueScrollTo from 'vue-scrollto'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  state: {
    locales: null,
    params: null,
    user: VueCookies.get('user') || null,
    service: VueCookies.get('service') || null,
    is_light_theme: VueCookies.get('theme') === 'light' ?? false,
    is_heb_lang: VueCookies.get('he_lang') === 'he' ?? window.navigator.language.substr(0, 2).toLowerCase() !== 'ru',
    lang: VueCookies.get('lang') ? VueCookies.get('lang') : window.navigator.language.substr(0, 2).toLowerCase() == 'ru' ? 'ru' : ( window.navigator.language.substr(0, 2).toLowerCase() == 'en' ? 'en' : 'he'),
    is_menu_shown: false,
    is_menu_profile_shown: false,
    is_menu_profile_open: false,
    is_popup_shown: false,
    is_loading: false,
    is_social_shown: true,
    services: { mortgage: 1, refMortgage: 2, credit: 3, refCredit: 4 },
    steps: { mortgage: [], refMortgage: [], credit: [], refCredit: [] },
    missed_steps: { mortgage: [], refMortgage: [], credit: [], refCredit: [] },
    all_steps: { mortgage: 5, refMortgage: 6, credit: 11, refCredit: 7 },
    banks: null,
    service_status: 3,
    popup: { id: null, withTitle: false, button: false, type: null },
    notices: null
  },
  mutations: {
    setLocales (state, locales) {
      state.locales = locales
    },
    setParams (state, params) {
      state.params = params
    },
    setBanks (state, banks) {
      state.banks = banks
    },
    saveUser (state, data) {
      state.user = data
      VueCookies.set('user', data, Infinity)
      Vue.axios.defaults.headers.common['X-Authorization'] = `Bearer ${data.token}`
    },
    setService (state, service) {
      state.service = service
      VueCookies.set('service', service)
    },
    logout (state) {
      state.user = null
      VueCookies.remove('user')
      VueCookies.remove('service')
      VueCookies.remove('mortgage')
      VueCookies.remove('refMortgage')
      VueCookies.remove('credit')
      VueCookies.remove('refCredit')
      Vue.axios.defaults.headers.common['X-Authorization'] = null
    },
    changeTheme (state) {
      state.is_loading = true
      const current = !state.is_light_theme
      VueCookies.set('theme', current ? 'light' : 'dark')
      window.location.reload()
    },
    changeLang (state, lang) {
      state.is_loading = true
      VueCookies.set('lang', lang)
      VueCookies.set('he_lang', lang)
      window.location.reload()
    },
    toggleMenu (state) {
      state.is_menu_shown = !state.is_menu_shown
    },
    toggleProfileMenu (state) {
      state.is_menu_profile_open = !state.is_menu_profile_open
    },
    openProfileMenu (state) {
      state.is_menu_profile_shown = true
    },
    closeProfileMenu (state) {
      state.is_menu_profile_shown = false
    },
    togglePopup (state) {
      state.is_popup_shown = !state.is_popup_shown
    },
    closeSocial (state) {
      state.is_social_shown = false
    },
    togglePopupText (state, obj = {}) {
      if (obj.id) VueScrollTo.scrollTo('.sidebar', 0)
      state.popup = {
        id: obj.id || null,
        withTitle: obj.withTitle || false,
        button: obj.button || false,
        type: obj.type || null
      }
    },
    closePopupText (state) {
      state.popup = { id: null, withTitle: false, button: false, type: null }
    },
    loading (state, status) {
      state.is_loading = status
    },
    setNotices (state, data) {
      state.notices = data
    },
    setSteps (state, steps) {
      state.steps = steps
    },
    setMissedSteps (state, missed_steps) {
      state.missed_steps = missed_steps
    },
    setAllSteps (state, all_steps) {
      state.all_steps = all_steps
    }
  },
  actions: {
    getData ({ commit, state}) {
      const localeName = 'name_' + state.lang
      const locales = Vue.axios.get('locales')
      const params = Vue.axios.get('params')
      const banks = Vue.axios.get('banks?sort=-priority&projection=id,tender,url,logo,' + localeName)
      let requests = [locales, params, banks]
      
      if (state.user) {
        const userParams = Vue.axios.get('user_params?user_id=' + state.user.id)
        requests.push(userParams)
      }

      commit('loading', true)
      Vue.axios.all(requests).then(Vue.axios.spread((...responses) => {
        commit('setLocales', responses[0].data)
        commit('setParams', responses[1].data)
        commit('setBanks', responses[2].data.filter(b => b.tender == 1))
        if (responses[3].data) {
          const params = responses[3].data
          if (params.service) commit('setService', params.service)
          if (params.steps) commit('setSteps', JSON.parse(params.steps))
          if (params.missed_steps) commit('setMissedSteps', JSON.parse(params.missed_steps))
          if (params.all_steps) commit('setAllSteps', JSON.parse(params.all_steps))
        }
        commit('loading', false)
      })).catch(() => { commit('loading', false) })
    },
    getNotices ({ commit, state}) {
      commit('loading', true)
      const userId = state.user ? state.user.id : 0
      Vue.axios.get('users/' + userId + '/data').then((response) => {
        commit('loading', false)
        commit('setNotices', response.data.unread)
      }).catch(() => { commit('loading', false) })
    },
    sendCode ({ state }, { code, type, email }) {
      const tempUser = VueCookies.get('tempUser') || {}
      const userId = state.user ? state.user.id : tempUser.id || 0
      const transferType = type || 'sms'
      Vue.axios.post('users/' + userId + '/' + transferType, { message: code, email: email })
    }
  }
})
