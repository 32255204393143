const refinanceCredit = [
  {
    path: '/refinance-credit/registration',
    name: 'Refinance credit registration',
    props: true,
    meta: { auth_free: true },
    component: () => import('../views/refinance_credit/step_1/0_Registration.vue')
  },
  {
    path: '/refinance-credit/members',
    name: 'Refinance Credit members',
    component: () => import('../views/refinance_credit/step_1/1_Members.vue')
  },
  {
    path: '/refinance-credit/clients',
    name: 'Refinance Credit clients',
    props: true,
    component: () => import('../views/refinance_credit/step_1/2_Client.vue')
  },
  {
    path: '/refinance-credit/amount-credits',
    name: 'Refinance Credit: Amount of credits',
    component: () => import('../views/refinance_credit/step_2/3_AmountCredits.vue')
  },
  {
    path: '/refinance-credit/about-credits',
    name: 'Refinance Credit: About Credits',
    props: true,
    component: () => import('../views/refinance_credit/step_2/4_AboutCredits.vue')
  },
  {
    path: '/refinance-credit/money',
    name: 'Refinance Credit money',
    component: () => import('../views/refinance_credit/step_2/5_HowMuchMoney.vue')
  },
  {
    path: '/refinance-credit/reg-payment',
    name: 'Refinance Credit reg payment',
    component: () => import('../views/refinance_credit/step_3/6_Payment.vue')
  },
  {
    path: '/refinance-credit/reg-bank-offers',
    name: 'Refinance Credit reg bank offers',
    props: true,
    component: () => import('../views/refinance_credit/step_3/7_BankOffers.vue')
  },
  {
    path: '/refinance-credit/income',
    name: 'Refinance Credit income',
    component: () => import('../views/refinance_credit/step_4/8_Income.vue')
  },
  {
    path: '/refinance-credit/about-incomes',
    name: 'Refinance Credit about incomes',
    component: () => import('../views/refinance_credit/step_4/9_AboutIncomes.vue')
  },
  {
    path: '/refinance-credit/expense',
    name: 'Refinance Credit expense',
    component: () => import('../views/refinance_credit/step_4/10_Expense.vue')
  },
  {
    path: '/refinance-credit/about-expenses',
    name: 'Refinance Credit about expenses',
    component: () => import('../views/refinance_credit/step_4/11_AboutExpenses.vue')
  },
  {
    path: '/refinance-credit/obligations',
    name: 'Refinance Credit obligations',
    component: () => import('../views/refinance_credit/step_4/12_Obligations.vue')
  },
  {
    path: '/refinance-credit/about-obligations',
    name: 'Refinance Credit about obligations',
    component: () => import('../views/refinance_credit/step_4/13_AboutObligations.vue')
  },
  {
    path: '/refinance-credit/problems',
    name: 'Refinance Credit problems',
    component: () => import('../views/refinance_credit/step_4/14_Problems.vue')
  },
  {
    path: '/refinance-credit/about-problems',
    name: 'Refinance Credit about problems',
    component: () => import('../views/refinance_credit/step_4/15_AboutProblems.vue')
  },
  {
    path: '/refinance-credit/delay',
    name: 'Refinance Credit delay',
    component: () => import('../views/refinance_credit/step_4/16_Delay.vue')
  },
  {
    path: '/refinance-credit/about-delay',
    name: 'Refinance Credit about delay',
    component: () => import('../views/refinance_credit/step_4/17_AboutDelay.vue')
  },
  {
    path: '/refinance-credit/something-else',
    name: 'Refinance Credit something else',
    component: () => import('../views/refinance_credit/step_4/18_SomethingElse.vue')
  },
  {
    path: '/refinance-credit/payment',
    name: 'Refinance Credit payment',
    component: () => import('../views/refinance_credit/step_4/19_Payment.vue')
  },
  {
    path: '/refinance-credit/bank-offers',
    name: 'Refinance Credit bank offers',
    props: true,
    component: () => import('../views/refinance_credit/step_4/20_BankOffers.vue')
  },
  {
    path: '/refinance-credit/offer',
    name: 'Refinance Credit offer',
    props: true,
    component: () => import('../views/refinance_credit/step_5/21_Offer.vue')
  }
]

export default refinanceCredit
