export const formMixin = {
  data: () => ({
    success: false,
    check_form: false
  }),
  created () {
    this.checkForm(false)
  },
  methods: {
    hint (validator, validationRule, reqieredMessage, errorMessage) {
      if (validator.$dirty && !validator.required) return reqieredMessage
      else if (validator.$dirty && !validationRule) return errorMessage
      else return 'empty'
    },
    hintArray (validator, rules) {
      const rule = rules.find(e => validator.$dirty && !e.rule) || {}
      return rule.message || 'empty'
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day} / ${month} / ${year}`
    },
    parseDate (date) {
      if (!date) return null
      const [day, month, year] = date.split(' / ')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    isValidDate (date) {
      // First check for the pattern
      if (!/^\d{4}-\d{1,2}-\d{1,2}$/.test(date)) return false

      // Parse the date parts to integers
      const parts = date.split("-")
      const day = parseInt(parts[2], 10)
      const month = parseInt(parts[1], 10)
      const year = parseInt(parts[0], 10)

      // Check the ranges of month and year
      if (year < 1000 || year > 3000 || month == 0 || month > 12) return false

      let monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ]

      // Adjust for leap years
      if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) monthLength[1] = 29

      // Check the range of the day
      return day > 0 && day <= monthLength[month - 1];
    },
    checkForm (field) {
      if (field) {
        field.$touch()
      }
      this.success = !this.$v.$invalid
    },
    formatDateForCard (date) {
      if (!date) return null
      const [year, month] = date.split('-')
      const newYear = `${year[2]}${year[3]}`
      return `${month} / ${newYear}`
    },
    nextElementfocus (current, next) {
      this.$v[current].$touch()
      if (!this.$v[current].$invalid) {
        this.$refs[current].blur()
        this.$refs[next].focus()
      }
    }
  }
}
