<template>
  <div class="menu menu_profile" :class="{ menu_active: $store.state.is_menu_profile_shown, menu_open: $store.state.is_menu_profile_open }">
    <div class="menu__container">
      <router-link :to="{ name: 'settings' }" class="menu__buttons menu__buttons_settings">
        {{ "settings" | localize }}
        <span class="icon-settings"></span>
      </router-link>
      <button @click="logout" class="menu__buttons menu__buttons_exit">
        {{ "exit" | localize }}
        <span class="icon-exit"></span>
      </button>

      <v-card class="b-avatar_container b-avatar">
        <div class="b-avatar_img-container" @click="$refs.uploadPhoto.click()" :title="'edit_photo' | localize">
          <img class="b-avatar_image" :src="getImg" v-if="user.photo"/>
          <input ref="uploadPhoto" class="b-avatar_file" type="file" accept="image/*" @change="uploadPhoto"/>
        </div>
        <div class="b-avatar_information">
          <v-card-title class="b-avatar_title b-avatar_title__position">
            <span v-if="user.name">{{ user.name }}</span>
            <span v-else>{{ 'name' | localize }}</span>
          </v-card-title>
          <v-card-title class="b-avatar_title b-avatar_title__position-number">
            <span v-if="user.phone">{{ user.phone }}</span>
            <span v-else>{{ 'form_phone_number' | localize }}</span>
          </v-card-title>
        </div>
      </v-card>

      <nav class="menu__nav">
        <ul @click="$store.commit('toggleProfileMenu')">
          <router-link :to="{ name: 'profile' }" class="menu__item" active-class="menu__item_active" exact tag="li">
            <span v-if="notice('profile') > 0" class="menu__item_notice desktop">{{ notice('profile') }}</span>
            {{ "personalInformation" | localize }}
            <span class="icon-user-1"></span>
            <span v-if="notice('profile') > 0" class="menu__item_notice mobile">{{ notice('profile') }}</span>
          </router-link>

          <router-link :to="{ name: 'services' }" class="menu__item" active-class="menu__item_active" tag="li">
            <span v-if="notice('services') > 0" class="menu__item_notice desktop">{{ notice('services') }}</span>
            {{ "services" | localize }}
            <span class="icon-star"></span>
            <span v-if="notice('services') > 0" class="menu__item_notice mobile">{{ notice('services') }}</span>
          </router-link>

          <router-link :to="{ name: 'documents' }" class="menu__item" active-class="menu__item_active" tag="li">
            <span v-if="notice('docs') > 0" class="menu__item_notice desktop">{{ notice('docs') }}</span>
            {{ "documents" | localize }}
            <span class="icon-doc"></span>
            <span v-if="notice('docs') > 0" class="menu__item_notice mobile">{{ notice('docs') }}</span>
          </router-link>

          <router-link :to="{ name: 'payments' }" class="menu__item" active-class="menu__item_active" tag="li">
            <span v-if="notice('payments') > 0" class="menu__item_notice desktop">{{ notice('payments') }}</span>
            {{ "titlePayments" | localize }}
            <span class="icon-card"></span>
            <span v-if="notice('payments') > 0" class="menu__item_notice mobile">{{ notice('payments') }}</span>
          </router-link>

          <router-link :to="{ name: 'notifications' }" class="menu__item" active-class="menu__item_active" tag="li">
            <span v-if="notice('notices') > 0" class="menu__item_notice desktop">{{ notice('notices') }}</span>
            {{ "notifications" | localize }}
            <span class="icon-message"></span>
            <span v-if="notice('notices') > 0" class="menu__item_notice mobile">{{ notice('notices') }}</span>
          </router-link>

          <router-link :to="{ name: 'settings' }" class="menu__item menu__item_min" active-class="menu__item_active" tag="li">
            {{ "settings" | localize }}
            <span class="icon-settings"></span>
          </router-link>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Menu',
  data: () => ({ user: { name: null, phone: null, photo: null } }),
  created () {
    this.user = {
      name: this.$store.state.user.name || null,
      phone: this.$store.state.user.phone || null,
      photo: this.$store.state.user.photo || null
    }
  },
  computed: {
    getImg () { return process.env.VUE_APP_API_HOST + this.user.photo },
  },
  methods: {
    notice (section) {
      return this.$store.state.notices && section in this.$store.state.notices ? this.$store.state.notices[section] : 0
    },
    logout () {
      this.axios.post('logout').then(() => {
        this.$store.commit('logout')
        this.$router.push('/')
      })
    },
    uploadPhoto () {
      this.img = this.$refs.uploadPhoto.files[0]
      if (!this.img) {
        this.user.photo = null
      } else {
        const reader = new FileReader()
        reader.readAsDataURL(this.img)
        reader.onload = () => {
          const user = this.$store.state.user || {}
          const formData = new FormData()
          formData.append('photo', this.img)
          this.$store.commit('loading', true)
          this.axios
            .post('users/' + user.id + '/photo', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
              this.user.photo = user.photo = response.data.photo
              this.$store.commit('saveUser', user)
              this.$store.commit('loading', false)
            })
            .catch((e) => {
              this.error = 'server_error'
              this.$store.commit('loading', false)
              this.$scrollTo('.error__container', 300, { offset: -400 })
            })
        }
      }
    }
  }
}
</script>
