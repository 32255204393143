export const commonMixin = {
  data: () => ({
    prevRoute: '/',
    isMobile: false,
    banks: [],
    services: [],
    currentService: null,
    serviceData: null,
    inputRules: [
      value => {
        const pattern = /[^а-яА-ЯёЁ ]/g
        return !pattern.test(value) || 'Только кириллица'
      },
    ]
  }),
  beforeRouteEnter(to, from, next) {
    next(vm => { vm.prevRoute = from.path})
  },
  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  },
  mounted () {
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },
  created () {
    this.setBanks()
    this.onResize()
  },
  methods: {
    sortBy (field, reverse, primer) {
      reverse = !reverse ? 1 : -1;
      const key = primer ? function(x) { return primer(x[field]) } : function(x) { return x[field] }
      return function(a, b) { return a = key(a), b = key(b), reverse * ((a > b) - (b > a)) }
    },
    setBanks () {
      if (this.$store.state.banks && this.$store.state.banks.length > 0) {
        const localeName = 'name_' + this.$store.state.lang
        this.banks = this.$store.state.banks.map((e) => ({ name: e[localeName], value: e.id }))
      }
    },
    onResize () {
      this.isMobile = window.innerWidth < 1025
    },
    getList (key, lenght) {
      const list = []
      for (let i = 1; i <= lenght; i++) {
        list.push({ name: this.$options.filters.localize(key + i), value: i })
      }
      return list
    },
    setLocale (lang) {
      if (lang == this.$store.state.lang) return
      this.$store.commit('changeLang', lang)
    },
    setTheme () {
      this.$store.commit('changeTheme')
    },
    password (length = 8) {
      return String.fromCodePoint(
        ...Array.from(
          { length: length },
          () => Math.floor(Math.random() * 57) + 65
        )
      )
    },
    isBestBank (id) {
      const banks = [...this.banks].sort((a, b) => a.return < b.return ? -1 : Number(a.return > b.return))
      return id === banks[0].id
    },
    closeSocial () {
      this.$store.commit('closeSocial')
    },
    closeDialog (el) {
      this[el] = false
    },
    setAllSteps (service, value) {
      const userId = this.$store.state.user.id || 0
      const allSteps = JSON.parse(JSON.stringify(this.$store.state.all_steps || {}))

      allSteps[service] = value
      this.axios.put('user_params', {user_id: userId, all_steps: JSON.stringify(allSteps)}).then(() => {
        this.$store.commit('setAllSteps', allSteps)
      })
    },
    addMissedSteps (service, missed_steps) {
      const userId = this.$store.state.user.id || 0
      let missedSteps = JSON.parse(JSON.stringify(this.$store.state.missed_steps || {}))
      
      missed_steps.forEach((url) => { missedSteps[service] = missedSteps[service].filter(s => s != url) })
      missed_steps.forEach((url) => { missedSteps[service].push(url) })
      this.axios.put('user_params', {user_id: userId, missed_steps: JSON.stringify(missedSteps)}).then(() => {
        this.$store.commit('setMissedSteps', missedSteps)
      })
    },
    dropSteps (service, current_step, missed_step) {
      const userId = this.$store.state.user.id || 0
      let steps = JSON.parse(JSON.stringify(this.$store.state.steps || {}))
      let missedSteps = JSON.parse(JSON.stringify(this.$store.state.missed_steps || {}))

      const find_current_step = steps[service].findIndex(s => s.url == current_step)
      const find_missed_step = missedSteps[service].findIndex(s => s == missed_step)
      
      if (find_current_step >= 0) {
        steps[service].length = find_current_step + 1
        this.axios.put('user_params', {user_id: userId, steps: JSON.stringify(steps)}).then(() => {
          this.$store.commit('setSteps', steps)
        })
      }
      if (find_missed_step >= 0) {
        missedSteps[service].length = find_missed_step
        this.$store.commit('setMissedSteps', missedSteps)
      }
    },
    getServiceStr (serviceId) {
      return Object.keys(this.$store.state.services).find(k => this.$store.state.services[k] === serviceId)
    },
    loadServices () {
      const userId = this.$store.state.user.id || 0
      const serviceId = this.$store.state.service ? this.$store.state.services[this.$store.state.service] : null

      this.$store.commit('loading', true)
      this.axios
        .get('users/' + userId + '/services')
        .then((response) => {
          if (response.data.length > 0) {
            this.services = response.data
            this.serviceData = serviceId ? response.data.find(s => s.service_id == serviceId) : response.data[0]
            this.currentService = this.serviceData.service_id
            this.$store.commit('setService', this.getServiceStr(this.currentService))
          } else {
            // Нет услуг
            this.error = 'no_services'
          }
          this.$store.commit('loading', false)
        })
        .catch(() => {
          this.error = 'server_error'
          this.$store.commit('loading', false)
        })
    }
  },
  computed: {
    bankSorted () {
      let banks
      switch (this.sort) {
        case 1:
          banks = [...this.banks].sort((a, b) =>
            a.return < b.return ? -1 : Number(a.return > b.return)
          )
          break
        case 2:
          banks = [...this.banks].sort((a, b) =>
            a.return < b.return ? 1 : a.return > b.return ? -1 : 0
          )
          break
        case 3:
          banks = [...this.banks].sort((a, b) =>
            a.payment < b.payment ? -1 : a.payment > b.payment ? 1 : 0
          )
          break
        case 4:
          banks = [...this.banks].sort((a, b) =>
            a.payment < b.payment ? 1 : a.payment > b.payment ? -1 : 0
          )
          break
        default:
          banks = this.banks
          break
      }
      return banks
    }
  }
}
