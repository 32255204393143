<template>
  <div class="loader" v-if="$store.state.is_loading">
    <div class="logo">BANKIM<span>ONLINE</span></div>
  </div>
</template>

<script>
export default {
  name: 'Loader'
}
</script>
