<template>
    <nav class="social" :class="cls">
        <ul>
            <li><a target="_blank" v-if="$store.state.params && $store.state.params.twitter" :href="$store.state.params.twitter" class="social__link icon-twitter"></a></li>
            <li><a target="_blank" v-if="$store.state.params && $store.state.params.facebook" :href="$store.state.params.facebook" class="social__link icon-facebook"></a></li>
            <li><a target="_blank" v-if="$store.state.params && $store.state.params.instagram" :href="$store.state.params.instagram" class="social__link icon-instagram"></a></li>
            <li><a target="_blank" v-if="$store.state.params && $store.state.params.youtube" :href="$store.state.params.youtube" class="social__link icon-youtube"></a></li>
        </ul>
    </nav>
</template>

<script>
export default {
  name: 'Social',
  props: {
    cls: { type: String, default: null }
  }
}
</script>
